import { useEffect, useRef } from 'react'
import { Box, Flex, Card } from '../primitives'
import GlobalSearch from './GlobalSearch'
import { useRouter } from 'next/router'
import { useHotkeys } from 'react-hotkeys-hook'
import Link from 'next/link'
import Image from 'next/image'
import { ConnectWalletButton } from 'components/ConnectWalletButton'
import NavItem from './NavItem'
import ThemeSwitcher from './ThemeSwitcher'
import HamburgerMenu from './HamburgerMenu'
import MobileSearch from './MobileSearch'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import { useMarketplaceChain, useMounted } from '../../hooks'
import { useAccount } from 'wagmi'
import CartButton from './CartButton'
import { AccountSidebar } from 'components/navbar/AccountSidebar'
import {  setActiveUser } from "../../firebase/config";
import ShimmerButton from '../../components/magicui/shimmer-button'

import * as HoverCard from '@radix-ui/react-hover-card'
import MainBanner from 'components/banner/MainBanner'
export const NAVBAR_HEIGHT = 136
export const NAVBAR_HEIGHT_MOBILE = 132

const Navbar = () => {
  const { theme } = useTheme()
  const { isConnected } = useAccount()
  const isMobile = useMediaQuery({ query: '(max-width: 960px' })
  const isMounted = useMounted()
  const { routePrefix } = useMarketplaceChain()
  const { address } = useAccount()

  let searchRef = useRef<HTMLInputElement>(null)

  const router = useRouter()
  useHotkeys('meta+k', (e) => {
    e.preventDefault()
    if (searchRef?.current) {
      searchRef?.current?.focus()
    }
  })

  if (!isMounted) {
    return null;
  }


  return isMobile ? (
    <Flex
      css={{
        height: NAVBAR_HEIGHT_MOBILE,
        width: '100%',
        borderBottom: '1px solid $gray4',
        zIndex: 999,
        background: '#0E0E10 !important',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between"
      direction={'column'}
    >
      <Box
        css={{
          display: 'flex',
          width: '100%',
        }}
      >
        <MainBanner />
      </Box>
      <Box
        css={{
          display: 'flex',
          width: '100%',
          height: '81px',
          justifyContent: 'center',
          alignItems: 'center',
          px: '$4',
        }}
      >
        <Box css={{ flex: 1 }}>
          <Flex align="center">
            <Link href={`/${routePrefix}`}>
              <Box css={{ width: 46, cursor: 'pointer' }}>
                <Image
                  src="/sharpe-nav-logo.svg"
                  width={36}
                  height={36}
                  alt="Sharpe"
                />
              </Box>
            </Link>
          </Flex>
        </Box>
        <Flex align="center" css={{ gap: '$3' }}>
          <MobileSearch key={`${router.asPath}-search`} />
          <CartButton />
          <HamburgerMenu key={`${router.asPath}-hamburger`} />
        </Flex>
      </Box>
    </Flex>
  ) : (
    <Flex
      css={{
        height: NAVBAR_HEIGHT,

        width: '100%',
        // maxWidth: 1920,
        mx: 'auto',
        borderBottom: '1px solid $gray4',
        zIndex: 999,
        background: '#0E0E10 !important',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between"
      direction={'column'}
    >
      <Box
        css={{
          display: 'flex',
          width: '100%',
        }}
      >
        <MainBanner />
      </Box>
      <Box
        css={{
          display: 'flex',
          width: '100%',
          height: '81px',
          justifyContent: 'center',
          alignItems: 'center',
          px: '$5',
          '@xl': {
            px: '$6',
          },
        }}
      >
        <Box
          css={{
            flex: 'unset',
            '@bp1300': {
              flex: 1,
            },
          }}
        >
          <Flex align="center">
            <Link href={`/${routePrefix}`}>
              <Box css={{ cursor: 'pointer' }}>
                <Image
                  src="/sharpe-nav-logo.svg"
                  width={32}
                  height={32}
                  alt="Reservoir"
                />
              </Box>
            </Link>
            <Flex
              align="center"
              css={{
                gap: '$5',
                ml: '$5',
              }}
            >
              <Link href={`/${routePrefix}`}>
                <NavItem>Explore</NavItem>
              </Link>

              <HoverCard.Root openDelay={200}>
                <HoverCard.Trigger>
                  <NavItem>Trending</NavItem>
                </HoverCard.Trigger>
                <HoverCard.Content sideOffset={24} align="start">
                  <Card
                    css={{
                      p: 24,
                      width: 240,
                      border: '1px solid $gray4',
                      borderRadius: 0,
                      background: '#27272A',
                    }}
                  >
                    <Flex css={{ gap: '$4' }} direction="column">
                      <Link href={`/${routePrefix}/collections/trending`}>
                        <NavItem>Collections</NavItem>
                      </Link>
                      <Link href={`/${routePrefix}/mints/trending`}>
                        <NavItem>Mints</NavItem>
                      </Link>
                    </Flex>
                  </Card>
                </HoverCard.Content>
              </HoverCard.Root>

              {false && (
                <Link href={`/${routePrefix}/collections/minting`}>
                  <NavItem>Mints</NavItem>
                </Link>
              )}
              {false && (
                <Link href="/swap">
                  <NavItem>Tokens</NavItem>
                </Link>
              )}
            </Flex>
          </Flex>
        </Box>
        <Box css={{ flex: 1, px: '$0' }}>
          <GlobalSearch
            ref={searchRef}
            placeholder="Search collections and addresses"
            containerCss={{
              width: '100%',
              background: 'transparent',
              borderRadius: '0px',
            }}
            key={router.asPath}
            css={{ background: 'transparent', borderRadius: '0px' }}
          />
        </Box>

        <Flex
          css={{
            gap: '$3',
            flex: 'unset',
            '@bp1300': {
              flex: 1,
            },
          }}
          justify="end"
          align="center"
        >
          <Flex css={{ gap: '$3', mr: 0, alignItems: 'center' }}>
           <div className='z-10 flex items-center justify-center'>
              <ShimmerButton className='shadow-2xl' onClick={() => window.open('https://stake.sharpe.ai/', '_blank')}>
                <span className='whitespace-pre-wrap text-center py-0 text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-[14px]'>
                  Earn $SAI
                </span>
              </ShimmerButton>
            </div>
            {/* <Box>
            <HoverCard.Root openDelay={120}>
              <HoverCard.Trigger>
                <a target="_blank" href={`https://docs.reservoir.tools/docs`}>
                  <NavItem>Developers</NavItem>
                </a>
              </HoverCard.Trigger>
              <HoverCard.Content sideOffset={24} align="start">
                <Card css={{ p: 24, width: 240, borderRadius: 0, background: '#27272A' }}>
                  <Flex css={{ gap: '$4' }} direction="column">
                    <a target="_blank" href={`https://reservoir.tools`}>
                      <NavItem>About Reservoir</NavItem>
                    </a>
                    <a
                      target="_blank"
                      href={`https://docs.reservoir.tools/docs`}
                    >
                      <NavItem>Docs</NavItem>
                    </a>

                    <a
                      target="_blank"
                      href={`https://docs.reservoir.tools/reference/overview`}
                    >
                      <NavItem>API Reference</NavItem>
                    </a>

                    <a
                      target="_blank"
                      href={`https://github.com/reservoirprotocol`}
                    >
                      <NavItem>Github</NavItem>
                    </a>

                    <a href={`https://testnets.reservoir.tools`}>
                      <NavItem>Testnet Explorer</NavItem>
                    </a>
                  </Flex>
                </Card>
              </HoverCard.Content>
            </HoverCard.Root>
          </Box> */}
            {isConnected && (
              <Link href={`/portfolio/${address || ''}?chain=${routePrefix}`}>
                <Box css={{ mr: '$2' }}>
                  <NavItem>Portfolio</NavItem>
                </Box>
              </Link>
            )}
          </Flex>

          {isConnected ? (
            <AccountSidebar />
          ) : (
            <Box css={{ maxWidth: '185px' }}>
              <ConnectWalletButton />
            </Box>
          )}
          <CartButton />
        </Flex>
      </Box>
    </Flex>
  )
}

export default Navbar